<template>
  <div class="container flex al jc-c">
    <el-col :sm="12" :lg="6">
      <el-result icon="success" title="文件生成成功" subTitle="你可使用生成的文件发起合同签署">
        <template slot="extra">
          <el-button type="primary" size="medium" @click="toCreate">发起签署</el-button>
          <el-button type="primary" size="medium" @click="download"
            >下载文件</el-button
          >
        </template>
      </el-result>
    </el-col>
  </div>
</template>

<script>
export default {


  data() {
    return {
      param: {},
    };
  },

  mounted() {
     console.log( window.location);
    let list = window.location.search.replace("?", "").split("&");
    let param = {};
    list.forEach((item) => {
      let v = item.split("=");
      param[v[0]] = v[1];
    });
    this.param = param;
  },

  methods: {
    toCreate(){
       window.location = window.location.origin+'/#/main/createSign?fileId='+this.param.fileId
    },
    download() {
      this.$get("user/esign/getFileStatus", {
        id: this.param.fileId,
      }).then((res) => {
        const a = document.createElement("a");
        a.style.display = "none";
          a.setAttribute('target', "_blank");

        a.setAttribute('download', "模板文件template.pdf");
        a.href = res.data.data.fileDownloadUrl;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    },
  },
};
</script>